new WOW().init();
$(window).on("load",function(){
});
$(function(){

    var $body = $('body');
    $body.addClass('start');

	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/smile-piece/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$body.prepend(svg);
	});

    //ページ内スクロール
    $.smooziee();

    //スクロールしたらクラスを付与
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $body.addClass("scroll");
        } else {
            $body.removeClass("scroll");
        }
    });

    /*クリックボックス*/
    $(".clickbox").click(function(){
         if($(this).find("a").attr("target")=="_blank"){
             window.open($(this).find("a").attr("href"), '_blank');
         }else{
             window.location=$(this).find("a").attr("href");
         }
     return false;
     });

    //開閉用ボタンをクリックでクラスの切替え
    $('.header_nav_btn').on('click', function () {
        $body.toggleClass('open');
    });

    /*--------------------------------------------------
    スマホのみ
    --------------------------------------------------*/
    $(function() {
        if (!isPhone())
            return;

    });

    function isPhone() {
        return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
    }

});