$(window).on("load",function(){
});
$(function(){
    // エラーページでは実行させない
    if(!($('body.error').length)){
        // console.log("エラーページではない");

        //離脱の警告
        // if($('#validation_form')) {
        if($('#validation_form').length){
            $(window).on('beforeunload', function() {
                return 'フォームは未送信です。\n' +
                    'このままページを移動するとフォームに入力したデータは保存されません。';
            });
            $('.js-no-withdrawal').on('click', function(e) {
                $(window).off('beforeunload');
            });
        }

        //セレクター　選択したら
        $('select').on('change',function(){
            if( this.value == '' ){
                this.style.color = '';
            } else {
                this.style.color = '#000';
            }
        });

        // 住所自動入力
        $('#zip').attr("onKeyUp", "AjaxZip3.zip2addr(this,'','address','address');");

        // 住所自動補完が完了したら
        AjaxZip3.onSuccess = function() {
            $('input[name=address]').focus(); // 番地、建物名にフォーカス
            // $('select[name=prefectures]').css({'color':'#000'}); //色変更
        };

        // カタ自動補完
        $.fn.autoKana('#name', '#kana', {
            katakana : true  //true：カタカナ、false：ひらがな（デフォルト）
        });

        //カタ自動補完時にバリデーションを実行
        $('#name').on('blur', function() {
            var kana = $('#kana');
            if(kana.val()) {
                kana.validationEngine('validate');
            }
        });


        var result = UAParser()
        // result.browserにブラウザに関する情報が入っています
        console.log(result.browser)


        if (result.device.type === 'mobile') {
            $("body").addClass("mobile");
        }

        if (result.device.type !== 'mobile') {

            if(result.browser.name !== 'Safari') {

                //お問い合わせ関連の対応
                $('#name').addClass('validate[required]');
                $('#kana').addClass('validate[required]');
                $('#mail').addClass('validate[required,custom[email]]');
                $('#message').addClass('validate[required]');
                $('#privacy-1').after('<span class="form-check-icon"></span>');
                $('#privacy-1').addClass('validate[required]');

                $('#shubetsu-1').after('<span class="form-check-icon"></span>');
                $('#shubetsu-1').addClass('required');
                $('#shubetsu_checkbox input').addClass('validate[minCheckbox[1]]');

                $('#sex-1').after('<span class="form-check-icon"></span>');
                $('#sex-1').addClass('required');
                $('#sex_radio input').addClass('validate[required]');
                $('#age').addClass('validate[required]');
                $('#tel').addClass('validate[required,custom[number]]');
                $('#zip').addClass('validate[required]');
                $('#address').addClass('validate[required]');
                $('#kininaru-1').after('<span class="form-check-icon"></span>');
                $('#kininaru-1').addClass('required');
                $('#kininaru_checkbox input').addClass('validate[minCheckbox[1]]');
                $('#service-1').after('<span class="form-check-icon"></span>');
                $('#service-1').addClass('required');
                $('#service_checkbox input').addClass('validate[minCheckbox[1]]');

                // $('#file').addClass('validate[checkFileType[jpg|jpeg|JPG|png]]');
                $('#file').addClass('required validate[required]');
                $('#file').after('<span class="form-check-icon"></span>');

                $('#kiyaku-1').after('<span class="form-check-icon"></span>');
                $('#kiyaku-1').addClass('validate[required]');

                //全体バリデーション&残りの入力項目カウント
                var $required = $('.required');
                var $disabled = $('.mw_wp_form_input .js-validation-disabled');
                $disabled.prop('disabled', 'disabled');

                var requiredNum = $required.length;
                var errorNum = requiredNum - $required.filter('.is-success').length;
                $('.items, .number').text(errorNum);

                $('#validation_form form').validationEngine('attach', {
                    promptPosition: 'topLeft',
                    addSuccessCssClassToField: 'is-success',
                    addFailureCssClassToField: 'is-error',
                    onFieldSuccess: function(field) {
                        requiredCountDown();
                    },
                    onFieldFailure: function(field) {
                        requiredCountDown();
                    }
                });

                function requiredCountDown () {
                    $('.number').text(requiredNum - $required.filter('.is-success').length);
                    if (requiredNum === $required.filter('.is-success').length) {
                        $disabled.prop('disabled', false);
                    } else {
                        $disabled.prop('disabled', 'disabled');
                    }
                }

               //残りの入力項目カウント　スクロールしたら表示
                var remaining = $('.remaining');
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 300) {
                        remaining.stop()
                            .css({'visibility':'visible'})
                            .animate({'opacity': '.9'}, 500, 'linear');
                    } else {
                        remaining.stop()
                            .css({'visibility':'hidden'})
                            .animate({'opacity': '0'}, 500, 'linear');
                    }
                });
            }
        }
    }
});